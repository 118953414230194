import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/Layout'
import { css } from '@emotion/core'

export default (props) => {

    return (
        <Layout>
            <div css={css`
                @media only screen and (max-width: 26.5625em) {
                    padding: 0 1.5rem;
                }
            `} className="projects">
                <div css={css`
                    @media only screen and (max-width: 26.5625em) {
                        padding: 2rem 0 !important;
                    }
                `} className="projects__container">
                    <h1 css={css`
                        position: absolute;
                        left: 0;
                        width: 100%;
                    `}>Projects</h1>
                    <div css={css`
                        @media only screen and (max-width: 26.5625em) {
                            padding: 0 !important;
                            justify-content: space-between;
                            margin-top: 6.5rem !important;
                            a {
                                display: flex;
                                justify-content: center;
                                align-items: center;
                            }
                        }
                    `} className="content">
                        <Link css={css`
                            flex: 2;
                        `} to="/about">
                            <img  style={{ width: '10rem' }} src="/images/columna.png" alt="Columna"/>
                        </Link>
                        <div css={css`
                            @media only screen and (max-width: 26.5625em) {
                                flex: unset !important;
                            }
                        `} className="content__inside">
                            <div className="images-first">
                                <Link state={{ prevPath: props.location.pathname }} to="/maroma">
                                    <img style={{ height: '10rem' }} src="/images/maroma.png" alt="Maroma"/>
                                </Link>
                                <Link state={{ prevPath: props.location.pathname }} to="/feria-maroma">
                                    <img style={{ height: '10rem' }} src="/images/feria-maroma.png" alt="Feria Maroma"/>
                                </Link>
                            </div>
                            <div className="images-second">
                                <Link state={{ prevPath: props.location.pathname }} to="/artech-plaza">
                                    <img style={{ height: '10rem' }} src="/images/artech-plaza.png" alt="Artech Plaza"/>
                                </Link>
                                <Link state={{ prevPath: props.location.pathname }} to="/muralista">
                                    <img style={{ height: '10rem' }} src="/images/muralista.png" alt="Muralista"/>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}